import { media } from '../../feed_item'
import { createAttributes } from '../../utils'
import { appendInternalTrackingSearchParams } from '../../../../utilities/analytics'
import actionButtonsPartial from '../../action_buttons'
import i18n from '../../../../../../utilities/translation'
import {
  isThreeDotEnabled,
  isMomentTheme,
  isPersonalizationEnabled
} from '../../utils'

const language = document.documentElement.lang
const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

const feedItemTemplate = feedItem => {
  const { link, title, isVideoLink, thumbnailUrl, duration, layout, contentApiId } = feedItem

  return `
    <section class="swiper-slide">
      <article
      ${createAttributes(feedItem)}>
        ${media(thumbnailUrl, isVideoLink, duration, layout)}
        <a class="block-item__link" href="${appendInternalTrackingSearchParams(
          link
        )}" data-mp-cct data-mp-external-content-link>Play Now
        <span></span></a>

        ${
          isPersonalizationEnabled &&
          isMomentTheme(app_theme) &&
          isThreeDotEnabled(layout)
            ? `
              <div class='block-item__actions'>
                <button id="personalize"
                  data-mp-three-dots-button
                  class="button button__icon button__icon--personalize svg--dark"
                  type="button"
                  data-mp-share-title="${title}"
                  data-mp-share-link="${link}"
                  data-mp-content-api-id="${contentApiId}"
                  data-gtm-id="three_dots" aria-label="${i18n('Three Dots', language)}"
                  data-modal-custom-trigger-target-personalization='modal--personalize'
                  data-gtm-event-type="click-element"
                  data-gtm-event-category="Three Dots"
                  data-gtm-event-action=""
                  data-gtm-event-label="${link}">
    
                  <div
                    class="icon-three-dots"
                    data-gtm-id="three_dots"
                    data-gtm-event-type="click-element"
                    data-gtm-event-category="Three Dots"
                    data-gtm-event-label="{{ link }}"></div>
                </button>
              </div>
            `
            : actionButtonsPartial('dark')
        }
    </article>
  </section>`
}

export default feedItemTemplate
