/**
 * A higher order function to handle key events. Especially useful in cases where you want multiple keys to
 * trigger the same event. Pass in the callback you want the keypress to trigger and an array
 * of keys (using either reserved keychar strings or the numeric keycode),
 * and get back out a wrapped version of your function to use as an eventListener callback that is
 * set to trigger only in cases where the keypress event is triggered by
 * one of the specified keys.
 *
 * Additional paramaters allow you to control the stopPropagation and preventDefault handling of the browser.
 * @param {object} options
 * @param {function} [options.fn = () => {}]
 * @param {Array<Number|String>} [options.keys = []]
 * @param {boolean} [options.stopProp = false]
 * @param {boolean} [options.prevDef = false]
 * @return {function} A wrapped version of your function to pass to use as an eventListener callback
 */
export const keyHandler = options => e => {
  const {
    fn = () => {
    },
    keys = [],
    stopProp = true,
    prevDef = true,
    stopImmediate = false
  } = options
  if (keys.includes(e.key)) {
    stopImmediate && e.stopImmediatePropagation()
    stopProp && e.stopPropagation()
    prevDef && e.preventDefault()
    return fn()
  }
}

interface IntersectionObserverInit {
  root?: Element | null
  rootMargin?: string
  threshold?: number
}

export const lazyload = (
  element: Element | HTMLElement,
  cb: Function,
  extraConfig?: IntersectionObserverInit
) => {
  const config = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
    ...extraConfig
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        cb()
        observer.unobserve(element)
      }
    })
  }, config)
  observer.observe(element)
}

export const isInViewport = (element, threshold?: number) => {
  return new Promise(resolve => {
    const o = new IntersectionObserver(([entry]) => {
      // Use generic is intersecting if a threshold is not provided.
      // Otherwise, use the threshold.
      if (threshold) {
        resolve(entry.intersectionRatio >= threshold)
      } else {
        resolve(entry.isIntersecting)
      }
      o.disconnect()
    })
    o.observe(element)
  })
}

export const initPerBlockType =
  (blocksLeftOnPage, initFunction) =>
    (blockIdOrClassname, eventName = '') => {
      try {
        const currentTypeBlocks = []

        blocksLeftOnPage = blocksLeftOnPage.filter(block => {
          if (block?.id) {
            if (!block.id.includes(blockIdOrClassname)) return true
          } else {
            if (!block.classList.value.includes(blockIdOrClassname)) return true
          }
          currentTypeBlocks.push(block)
          return false
        })

        if (currentTypeBlocks.length) {
          if (eventName) {
            currentTypeBlocks.forEach(block => {
              block.addEventListener(eventName, () => {
                initFunction(block)
              })
            })
          } else {
            currentTypeBlocks.forEach(block => initFunction(block))
          }
        }

        if (!blocksLeftOnPage.length) return
      } catch (e) {
        console.error(`Init per blocktype error for ${blockIdOrClassname}`, e)
      }
    }

export const setEventListenersActionButtons = (eventTarget, eventAction, props) => {
  eventTarget.addEventListener('click', e => {
    eventAction(e, props)
  })
  eventTarget.addEventListener('keyup', e => {
    // Buttons will simulate click events and we don't want duplication
    if (e.type !== 'keyup') {
      return
    }
    if (e.key !== 'Enter') {
      return
    }
    eventAction(e, props)
  })
}

export const onInViewport = (el: Element, callback: () => void) => {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(async (entry) => {
      if (entry.target.getBoundingClientRect().top <= window.innerHeight) {
        observer.disconnect();
        callback();
      }
    })
  })

  observer.observe(el)
}