import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

Swiper.use([Navigation, Pagination])

const init = element => {
  const elementMain = element.querySelector('.block__main')
  const elementPagination = element.querySelector('.swiper-pagination')

  new Swiper(elementMain, {
    spaceBetween: 0,
    slidesPerView: 1.0,
    loop: false,
    pagination: {
      el: elementPagination,
      clickable: false
    },
    a11y: {
      enabled: true
    }
  })
}

const initHoroscopeCarousel = (el: HTMLElement) => {
    init(el)
}

export default initHoroscopeCarousel
