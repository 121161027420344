import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { setTimeDifference } from '../../../timestamp/utils'
import { header, footer, actions, media, app_theme } from '../feed_item'
import { createAttributes, isMomentTheme, removeSpecialCharacters } from '../utils'
import i18n from 'content/src/assets/utilities/translation'

const language = document.documentElement.lang

const momentTemplate = (
  {
    credit,
    title,
    pub_time,
    description,
    link,
    thumbnailUrl,
    isSponsored,
    publisherDarkLogo,
    publisherLogo,
    publisherName,
    isVideoLink,
    ms_news,
    contentApiId,
    body
  },
  optional: any = {}
) =>
  `<div class="block-item__header" style="--bg-image: url(${thumbnailUrl})">
        ${optional.show_back_button ? '<button class="back"></button>' : ''}
        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news
        )}
        <a href="${appendInternalTrackingSearchParams(link)}"         
          data-gtm-event-category="content_reveal_external_click" 
          data-gtm-event-type="click-element"
          data-gtm-event-action="${link}"
          data-gtm-event-label="${link}"
          data-mp-cct 
          class="block-item__title ${language}"> 
            ${title}
        </a>
        <div class="block-item__header-footer">
            ${credit ? `<span class="credit">${credit}</span>` : ''}
            ${
              pub_time
                ? `<span class="block-item__timestamp">${
                    !!new Date(pub_time) ? setTimeDifference(pub_time, language) : pub_time
                  }</span>`
                : ''
            }
        ${actions('stub', title, link, contentApiId)}
        </div>
    </div>
    <a href="${appendInternalTrackingSearchParams(link)}"         
    data-gtm-event-category="content_reveal_external_click" 
    data-gtm-event-type="click-element"
    data-gtm-event-action="${link}"
    data-gtm-event-label="${link}"
    data-mp-cct class="block-item__body">
      <div class="block-item__description-wrapper">
      ${
        body
          ? `<div class="full_body ${language}">
              ${body}
            </div>`
          : ''
      }
      ${
        description && !body
          ? `<div class="block-item__description ${language}">
              ${removeSpecialCharacters(description)}
            </div>`
          : ''
      }
            <div class="block-item__engagement-subtitle">
              <button class="button button--primary-small">${i18n('Read More', language)}</button>
            </div>
      </div>
    </a>`

const stubTemplate = (feedItem, optional: any = {}) => {
  let template = ''

  if (isMomentTheme(app_theme) || optional.force_moment_template) {
    template = momentTemplate(feedItem, optional)
  }
  return `
      <article
      ${createAttributes(feedItem)}>
      ${template}
    </article>`
}

export default stubTemplate
